import { useMediaQuery } from "react-responsive";

export const useDefaultMediaQuery = () =>
  useMediaQuery({ query: "(min-width: 768px)" });

export const useMobileMediaQuery = () =>
  useMediaQuery({ query: "(max-width: 767px)" });

export const Mobile = ({ children }: any) => {
  const isMobile = useMobileMediaQuery();
  return isMobile ? children : null;
};

export const Default = ({ children }: any) => {
  const isDefault = useDefaultMediaQuery();
  return isDefault ? children : null;
};
