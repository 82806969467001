import { Checkbox } from "@blueprintjs/core";
import { sortBy } from "lodash";
import React, { useMemo, useState } from "react";
import { FLOWERS } from "../data/Flowers";
import { PageFooter } from "../shared/PageFooter";
import { PageWithNavBar } from "../shared/PageWithNavBar";
import { SimpleTextSection } from "../shared/SimpleTextSection";
import styles from "../styles/Wholesale.module.scss";
import { FlowerCard } from "./FlowerCard";

const WHOLESALE_TITLE = "Wholesale";
const WHOLESALE_DESCRIPTION =
  "From June through early October, we offer wholesale-priced bunches tailored for florists and floral designers seeking the freshest, most exquisite blooms to elevate their creations. Please email to inquire about pricing.";

export const Wholesale = React.memo(function WholesaleInternal() {
  const [showSpring, setShowSpring] = useState<boolean>(true);
  const [showSummer, setShowSummer] = useState<boolean>(true);

  const handleShowSpringChange = () => {
    setShowSpring(!showSpring);
  };

  const handleShowSummerChange = () => {
    setShowSummer(!showSummer);
  };

  const filteredFlowers = useMemo(
    () =>
      sortBy(
        FLOWERS.filter(
          (flower) =>
            (showSummer && flower.season === "SUMMER") ||
            (showSpring && flower.season === "SPRING")
        ),
        "name"
      ),
    [showSpring, showSummer]
  );

  return (
    <PageWithNavBar>
      <SimpleTextSection
        title={WHOLESALE_TITLE}
        description={WHOLESALE_DESCRIPTION}
      />
      <div className={styles.header}>
        Staple Crops
        <div className={styles.filtersContainer}>
          <div className={styles.title}>Show Varieties:</div>
          <Checkbox
            checked={showSpring}
            label="Spring"
            onChange={handleShowSpringChange}
            className={styles.filter}
          />
          <Checkbox
            checked={showSummer}
            label="Summer"
            onChange={handleShowSummerChange}
            className={styles.filter}
          />
        </div>
      </div>
      <div className={styles.flowerGrid}>
        {filteredFlowers.map((flower, index) => (
          <FlowerCard flower={flower} key={"flower_" + index} />
        ))}
      </div>
      <PageFooter />
    </PageWithNavBar>
  );
});
