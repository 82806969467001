import React from "react";
import {
  IMG_CHRIS_AND_WILLIAM,
  IMG_FAMILY_PHOTO,
  IMG_FARM_TREE,
  SPRING_HORIZONTAL_SMOKE_WHITE,
} from "../data/Images";
import { PageFooter } from "../shared/PageFooter";
import { PageWithNavBar } from "../shared/PageWithNavBar";
import { SimpleTextBackgroundImageSection } from "../shared/SimpleTextBackgroundImageSection";
import { SimpleTextBanner } from "../shared/SimpleTextBanner";
import { SimpleTextImageSection } from "../shared/SimpleTextImageSection";

const ABOUT_US_IMAGE = IMG_FAMILY_PHOTO;
const ABOUT_US_TITLE = "About Us";
const ABOUT_US_INTRO = (
  <React.Fragment>
    <p>{`Welcome to our farm!  We're Janine and Chris, a couple who met at OSU playing frisbee. After getting married, we moved onto land with some friends and started this farm, which turned out to be a surprising intersection of our interests. It allows Janine to use her creative side working to design floral arrangements with a touch of elegance and charm tailored for each season, and Chris to go on and on about cover crops.`}</p>
  </React.Fragment>
);

const ABOUT_US_EDUCATORS = (
  <React.Fragment>
    <p>{`We’re both educators, so we get to use our summers to farm and lean on our complementary skill sets to run the business. Chris has a background in engineering interested in the systems approach to sustainable agriculture. Janine uses her communication, organization, and aesthetic skills to run marketing, create wedding bouquets, run guided bloom bars at retirement homes and to convince Chris she is interested in whatever he just said about microarthropods.`}</p>
    <p>{`We are always looking to implement sustainable and regenerative farming systems. So far, this includes multi-species cover crop rotations, spring-fed solar irrigation, vermicompost systems, rotationally pastured chickens and a focus on finding perennial species that work for us and our land. Our hope is that each year our farm becomes a little more productive, efficient, and self-sustaining.`}</p>
    <p>{`We are parents of two young children, 1 and 3, making this phase in our lives very joyful and a great time to launch a farm business. After all, new parents are generally well-rested with tons of free time and mental and physical energy. I know! Let’s start a business that requires lots of physical labor, creative thinking, attention to detail, aaanddd let’s be colleagues! :) :) :)`}</p>
    <p>{`Seriously though, we are grateful for the opportunity to cultivate something special. What might have once seemed borderline insane has blossomed into our family’s favorite endeavor. Working on the farm provides countless special moments with our children, whether that be pre-dawn flower harvesting wearing headlamps and a baby backpack, digging potatoes with our toddler (as exciting as an easter egg hunt), or wild loose chicken chases.`}</p>
  </React.Fragment>
);

const ABOUT_US_FINAL = (
  <React.Fragment>
    <p>
      This farm is not just our business—it’s our shared adventure and it has
      allowed us to invest our energies into something that nurtures both our
      family, our land and the community. We’re not totally sure where this
      business will take us, but we’re excited to be creating something and
      constantly&nbsp;<s>screwing up</s>&nbsp;learning.
    </p>
  </React.Fragment>
);

const STAY_IN_TOUCH_TITLE = "Stay in Touch";
const STAY_IN_TOUCH_DESCRIPTION =
  "If you'd like to be added to our email list to receive up-to-date reminders of bouquet subscriptions or to learn about what's fresh and available for purchase each week on the farm, please reach out with your email so we can add you to the list!";

export const AboutUs = React.memo(function AboutUsInternal() {
  return (
    <PageWithNavBar>
      <SimpleTextImageSection
        image={ABOUT_US_IMAGE}
        title={ABOUT_US_TITLE}
        description={ABOUT_US_INTRO}
        endDivider={true}
      />
      <SimpleTextBackgroundImageSection
        description={ABOUT_US_EDUCATORS}
        image={IMG_FARM_TREE}
      />
      <SimpleTextImageSection
        image={IMG_CHRIS_AND_WILLIAM}
        description={ABOUT_US_FINAL}
        imageRight={true}
        endDivider={true}
        endDividerImage={SPRING_HORIZONTAL_SMOKE_WHITE}
      />
      <SimpleTextBanner
        title={STAY_IN_TOUCH_TITLE}
        description={STAY_IN_TOUCH_DESCRIPTION}
      />
      <PageFooter />
    </PageWithNavBar>
  );
});
