import React from "react";
import styles from "../styles/SimpleTextBanner.module.scss";

interface SimpleTextBannerProps {
  title?: string;
  description: string | JSX.Element;
  backgroundColor?: string;
  textColor?: string;
  fontWeight?: string;
}

export const SimpleTextBanner = React.memo(function SimpleTextBannerInternal({
  title,
  description,
  backgroundColor,
  textColor,
  fontWeight,
}: SimpleTextBannerProps) {
  return (
    <div
      className={styles.contentContainer}
      style={{
        backgroundColor: backgroundColor,
        color: textColor,
        fontWeight: fontWeight,
      }}
    >
      {title != null && (
        <div
          className={styles.title}
          style={{
            color: textColor,
            borderBottomColor: textColor,
            fontWeight: fontWeight,
          }}
        >
          {title}
        </div>
      )}
      <div className={styles.description} style={{ color: textColor }}>
        {description}
      </div>
      <div
        className={styles.decoration}
        style={{
          backgroundColor: backgroundColor,
        }}
      >
        <img
          className={styles.decorationLogo}
          src="/img/logo/BackAndForthFarm_SprigHorizontal_SmokeWhite.png"
          alt=""
        />
      </div>
    </div>
  );
});
