import { EventType } from "../data/Events";

export enum NavTab {
  HOME = "HOME",
  ABOUT_US = "ABOUT_US",
  SUBSCRIPTIONS = "SUBSCRIPTIONS",
  EVENTS = "EVENTS",
}

export interface BaseAppState {
  selectedTab: NavTab;
  selectedEvent: EventType;
}

export const INITIAL_BASE_APP_STATE: BaseAppState = {
  selectedTab: NavTab.HOME,
  selectedEvent: EventType.MARKETS,
};

export const getInitialBaseAppState = (): BaseAppState =>
  INITIAL_BASE_APP_STATE;
