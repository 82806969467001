import { Card } from "@blueprintjs/core";
import React from "react";
import { Flower } from "../data/Flowers";
import styles from "../styles/FlowerCard.module.scss";

interface FlowerCardProps {
  flower: Flower;
}

export const FlowerCard = React.memo(function FlowerCardInternal({
  flower,
}: FlowerCardProps) {
  return (
    <Card className={styles.flowerCard}>
      <div
        className={styles.imageContainer}
        style={{
          backgroundImage: `url(${flower.image})`,
        }}
      />
      <div className={styles.title}>{flower.name}</div>
    </Card>
  );
});
