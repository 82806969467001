import classNames from "classnames";
import React from "react";
import styles from "../styles/SimpleTextBackgroundImageSection.module.scss";

interface SimpleTextBackgroundImageSectionProps {
  description: string | JSX.Element;
  image: string;
}

export const SimpleTextBackgroundImageSection = React.memo(
  function SimpleTextBackgroundImageSectionInternal({
    description,
    image,
  }: SimpleTextBackgroundImageSectionProps) {
    const textColumn = (
      <div className={classNames(styles.column, styles.textColumn)}>
        <div className={styles.description}>{description}</div>
      </div>
    );

    return (
      <div
        className={styles.baseContainer}
        style={{
          backgroundImage: `url(${image})`,
        }}
      >
        <div className={styles.baseContainerOverlay}></div>
        {textColumn}
      </div>
    );
  }
);
