import React from "react";
import pageStyles from "../styles/Page.module.scss";

export const PageFooter = React.memo(function PageFooterInternal() {
  return (
    <div className={pageStyles.pageFooter}>
      <div>
        <img
          className={pageStyles.footerLogo}
          src="/img/logo/BackAndForthFarm_B&F_Slant_CaputMortuum.png"
          alt=""
        />
      </div>
      <div className={pageStyles.footerText}>
        &copy; Back and Forth Farm 2024
      </div>
      <div className={pageStyles.footerSubtext}>
        backandforthfarms@gmail.com
      </div>
    </div>
  );
});
