import React from "react";
import { Subscription } from "../data/Subscriptions";
import styles from "../styles/SubscriptionPreview.module.scss";

interface SubscriptionPreviewProps {
  subscription: Subscription;
}

export const SubscriptionPreview = React.memo(function SubscriptionPreview({
  subscription,
}: SubscriptionPreviewProps) {
  const baseFlowers = subscription.baseFlowers.join(", ");

  return (
    <div className={styles.subscriptionPreviewContainer}>
      <div className={styles.textSection}>
        <div className={styles.description}>{subscription.description}</div>
      </div>
      <div className={styles.textSection}>
        <div>
          <span className={styles.label}>Base: </span>
          <span>{baseFlowers}</span>
        </div>
      </div>
      <div className={styles.textSection}>
        <div className={styles.time}>{subscription.time}</div>
        <div
          className={styles.length}
        >{`${subscription.length}, delivered`}</div>
        <div className={styles.price}>{subscription.price}</div>
      </div>
    </div>
  );
});
