import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AboutUs } from "./about/AboutUs";
import { Events } from "./events/Events";
import { Home } from "./home/Home";
import "./index.css";
import { store } from "./state/store";
import { Subscriptions } from "./subscriptions/Subscriptions";
import { Wholesale } from "./wholesale/Wholesale";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/subscriptions" element={<Subscriptions />} />
          <Route path="/events" element={<Events />} />
          <Route path="/wholesale" element={<Wholesale />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);
