import {
  ELEVATION_1,
  ELEVATION_2,
} from "@blueprintjs/core/lib/esm/common/classes";
import classNames from "classnames";
import React from "react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import styles from "../styles/ImageCarouselWithTextSection.module.scss";

interface ImageCarouselWithTextSectionProps {
  title: string;
  description: string | JSX.Element;
  images: Array<string>;
  imageRight?: boolean;
  backgroundColor?: string;
  portraitImages?: boolean;
  endDivider: boolean;
  endDividerImage?: string;
  carouselRef?: React.RefObject<HTMLDivElement>;
}

export const ImageCarouselWithTextSection = React.memo(
  function ImageCarouselWithTextSectionInternal({
    title,
    description,
    images,
    imageRight,
    backgroundColor,
    portraitImages,
    endDivider,
    endDividerImage,
    carouselRef,
  }: ImageCarouselWithTextSectionProps) {
    const imageColumn = (
      <div
        className={classNames(styles.column, {
          [styles.portraitImages]: portraitImages,
        })}
      >
        <div className={classNames(styles.imageContainer, ELEVATION_2)}>
          <Swiper
            pagination={true}
            modules={[Autoplay, Pagination]}
            centeredSlides={true}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            className={styles.swiperComponent}
          >
            {images.map((image, index) => (
              <SwiperSlide key={"image_" + index}>
                <div
                  className={styles.image}
                  style={{
                    backgroundImage: `url(${image})`,
                  }}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    );

    const textColumn = (
      <div
        className={classNames(styles.column, styles.textColumn, ELEVATION_1)}
        style={{ backgroundColor: backgroundColor }}
      >
        <div className={styles.title}>{title}</div>
        <div className={styles.description}>{description}</div>
        {endDivider && endDividerImage ? (
          <div>
            <img
              className={styles.decorationLogo}
              src={endDividerImage}
              alt=""
            />
          </div>
        ) : (
          <div className={styles.decorationDivider} />
        )}
      </div>
    );

    return (
      <div className={styles.baseContainer} ref={carouselRef}>
        {imageRight ? (
          <React.Fragment>
            {textColumn} {imageColumn}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {imageColumn} {textColumn}
          </React.Fragment>
        )}
      </div>
    );
  }
);
