// LOGOS
export const BACK_AND_FORTH_SLANT_WITH_SPRIG_PRUSSIAN_BLUE =
  "/img/logo/BackAndForthFarm_SlantWSprig_PrussianBlue.png";
export const SPRING_HORIZONTAL_SMOKE_WHITE =
  "/img/logo/BackAndForthFarm_SprigHorizontal_SmokeWhite.png";

// HOME PAGE
export const IMG_FLOWER_BUCKET = "/img/home/flower_bucket.jpg";
export const IMG_LAND = "/img/home/land.jpg";
export const IMG_BULBS = "/img/home/bulbs.jpg";

// ABOUT US PAGE
export const IMG_FAMILY_PHOTO = "/img/about/family.jpg";
export const IMG_CHRIS_AND_WILLIAM = "/img/about/chris_and_william.jpg";
export const IMG_FARM_TREE = "/img/about/farm_tree.jpg";

// SUBSCRIPTIONS
export const IMG_SUMMER__DOORSTEP = "/img/subscriptions/summer_doorstep.jpg";
export const IMG_SUMMER_STEPS = "/img/subscriptions/summer_steps.jpg";
export const IMG_SUMMER_VASE_PINK = "/img/subscriptions/summer_vase_pink.jpg";
export const IMG_SUMMER_VASE_WHITE = "/img/subscriptions/summer_vase_white.jpg";
export const IMG_SPRING_BOUQUET = "/img/subscriptions/spring_bouquet.jpg";
export const IMG_SPRING_VASE = "/img/subscriptions/spring_vase.jpg";
export const IMG_SPRING_MIXED = "/img/subscriptions/spring_mixed.jpg";
export const IMG_SPRING_YELLOW = "/img/subscriptions/spring_yellow.jpg";

// EVENTS
export const MARKET = "/img/events/market.jpg";
export const FLOWER_PILE = "/img/events/flower_pile.jpg";
export const MARKET_THUMBNAIL = "/img/events/market_thumbnail.jpg";
export const BLOOM_BAR = "/img/events/bloombar.jpg";
export const STUDENT_GROUP_BAR = "/img/events/group_bar.jpg";
export const BLOOM_BAR_TWO = "/img/events/bloom_bar_2.jpg";
export const BLOOM_BAR_THUMBNAIL = "/img/events/bloombar_thumbnail.jpg";
export const YOU_PICK_FARM = "/img/events/you_pick_farm.jpg";
export const SHOES_AND_FLOWERS = "/img/events/shoes_and_flowers.jpg";
export const WILLIAM_PUMPKIN = "/img/events/william_pumpkin.jpg";
export const YOU_PICK_THUMBNAIL = "/img/events/youpick_thumbnail.jpg";
export const JANINE_WEDDING = "/img/events/wedding.jpg";
export const ZEUS_WEDDING = "/img/events/wedding_zeus.jpg";
export const ZEUS_WEDDING_BRIDE = "/img/events/wedding_thumbnail.jpg";

// WHOLESALE
export const IMG_BLACK_EYED_SUSAN = "/img/flowers/black_eyed_susan.jpg";
export const IMG_CELOSIA = "/img/flowers/celosia.jpg";
export const IMG_CHINA_ASTER = "/img/flowers/china_aster.jpg";
export const IMG_COSMOS = "/img/flowers/cosmos.jpg";
export const IMG_DAFFODILS = "/img/flowers/daffodils.jpg";
export const IMG_DAHLIAS = "/img/flowers/dahlias.jpg";
export const IMG_GOMPHRENA = "/img/flowers/gomphrena.jpg";
export const IMG_GRAPE_HYACINTH = "/img/flowers/grape_hyacinth.jpg";
export const IMG_HYDRANGEA = "/img/flowers/hydrangea.jpg";
export const IMG_SUNFLOWERS = "/img/flowers/sunflowers.jpg";
export const IMG_TULIPS = "/img/flowers/tulips.jpg";
export const IMG_ZINNIA = "/img/flowers/zinnia.jpg";
export const IMG_HYACINTH = "/img/flowers/hyacinth.jpg";
