import React from "react";
import { useMobileMediaQuery } from "../state/ResponseivePage";
import pageStyles from "../styles/Page.module.scss";
import { MobileNavigationBar } from "./MobileNavigationBar";
import { NavigationBar } from "./NaivgationBar";

export const PageWithNavBar = React.memo(function PageWithNavBarInternal({
  children,
}: React.PropsWithChildren) {
  const isMobile = useMobileMediaQuery();

  return (
    <div className={pageStyles.pageContainer}>
      {isMobile ? <MobileNavigationBar /> : <NavigationBar />}
      <div className={pageStyles.scrollContainer}> {children}</div>
    </div>
  );
});
