import { BaseAppState, INITIAL_BASE_APP_STATE } from "./BaseAppState";

export interface GlobalState {
  baseAppState: BaseAppState;
}

export function createInitialGlobalState(): GlobalState {
  return {
    baseAppState: INITIAL_BASE_APP_STATE,
  };
}
