import { defineAction } from "redoodle";
import { EventType } from "../data/Events";
import { NavTab } from "./BaseAppState";

export const PREFIX = "@back-and-forth-farm//baseApp";

export const selectTab = defineAction(`${PREFIX}//selectTab`)<NavTab>();
export const selectEvent = defineAction(`${PREFIX}//selectEvent`)<EventType>();

export const BaseAppActions = {
  selectTab,
  selectEvent,
};
