export interface SocialMediaSite {
  name: string;
  link: string;
  img: string;
}

export const SOCIAL_MEDIA_SITES: SocialMediaSite[] = [
  {
    name: "facebook",
    link: "https://www.facebook.com/profile.php?id=61551036233389",
    img: "/img/social/Facebook_Logo_Secondary.png",
  },
  {
    name: "instagram",
    link: "https://www.instagram.com/backandforthfarm/",
    img: "/img/social/Instagram_Glyph_White.svg",
  },
];
