import { createSelector } from "reselect";
import { BaseAppState } from "./BaseAppState";
import { GlobalState } from "./globalState";

export const getSelectedEvent = createSelector(
  (state: GlobalState) => state.baseAppState,
  (bassAppState: BaseAppState) => {
    return bassAppState.selectedEvent;
  }
);
