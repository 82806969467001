import {
  IMG_SPRING_BOUQUET,
  IMG_SPRING_MIXED,
  IMG_SPRING_VASE,
  IMG_SPRING_YELLOW,
  IMG_SUMMER_STEPS,
  IMG_SUMMER_VASE_PINK,
  IMG_SUMMER_VASE_WHITE,
  IMG_SUMMER__DOORSTEP,
} from "./Images";

export enum SubscriptionType {
  SPRING = "SRPING",
  SUMMER = "SUMMER",
}

export interface Subscription {
  name: string;
  description: string;
  images: Array<string>;
  type: SubscriptionType;
  baseFlowers: Array<string>;
  time: string;
  price: string;
  length: string;
  color: string;
}

export const SUBSCRIPTIONS: Subscription[] = [
  {
    name: "Spring Medley",
    description: `Our spring subscription offers a delightful variety, starting with a week of vibrant daffodils, followed by a week of enchanting tulips, and concluding with a mixed bouquet bursting with seasonal charm. Experience the first splashes of color for the season as you welcome the vibrant energy of spring into your home with our handpicked selection of fresh blooms`,
    type: SubscriptionType.SPRING,
    images: [
      IMG_SPRING_BOUQUET,
      IMG_SPRING_VASE,
      IMG_SPRING_MIXED,
      IMG_SPRING_YELLOW,
    ],
    time: "April - May",
    price: "$90",
    length: "3 weeks",
    color: "#73704c",
    baseFlowers: ["Tulip", "Daffodil", "Hyacinth"],
  },
  {
    name: "Summer Medley",
    description: `Dive into the vibrant hues and fragrant blooms of the season with our handpicked selection of summer flowers. Our subscription features a delightful mix of seasonal favorites, including cheerful sunflowers, colorful zinnias, and whimsical gomphrena. From sunny days to warm evenings, bring the beauty of summer indoors with our Summer Medley Flower Subscription.`,
    type: SubscriptionType.SUMMER,
    images: [
      IMG_SUMMER_STEPS,
      IMG_SUMMER_VASE_PINK,
      IMG_SUMMER_VASE_WHITE,
      IMG_SUMMER__DOORSTEP,
    ],
    time: "July - Oct",
    price: "$120",
    length: "4 weeks",
    color: "#bf9788",
    baseFlowers: ["Zinnia", "Sunflower", "Cosmo", "Hydrangea"],
  },
];
