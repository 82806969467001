import { combineReducers } from "redoodle";
import { createStore, Store } from "redux";
import { baseAppReducer } from "./BaseAppReducer";
import { createInitialGlobalState, GlobalState } from "./globalState";

const rootReducer = combineReducers({
  baseAppState: baseAppReducer,
});

export const store: Store<GlobalState> = createStore(
  rootReducer as any,
  createInitialGlobalState()
);
