import {
  BLOOM_BAR,
  BLOOM_BAR_THUMBNAIL,
  BLOOM_BAR_TWO,
  FLOWER_PILE,
  JANINE_WEDDING,
  MARKET,
  MARKET_THUMBNAIL,
  SHOES_AND_FLOWERS,
  STUDENT_GROUP_BAR,
  WILLIAM_PUMPKIN,
  YOU_PICK_FARM,
  YOU_PICK_THUMBNAIL,
  ZEUS_WEDDING,
  ZEUS_WEDDING_BRIDE,
} from "./Images";

export enum EventType {
  WEDDINGS = "WEDDINGS",
  BLOOM_BAR = "BLOOM_BAR",
  MARKETS = "MARKETS",
  FARM_PHOTOS = "FARM_PHOTOS",
  YOU_PICK = "YOU_PICK",
}

export interface Event {
  name: string;
  description: string;
  images: Array<string>;
  thumbnailImg: string;
  type: EventType;
}

export const EVENTS: Event[] = [
  {
    name: "Markets",
    description: `Discover the freshest blooms straight from our farm to your hands! Join us at local markets for a vibrant selection of seasonal flowers, lovingly cultivated by our family. Brighten your home or surprise a loved one with nature's beauty, handpicked just for you.`,
    images: [MARKET, FLOWER_PILE],
    thumbnailImg: MARKET_THUMBNAIL,
    type: EventType.MARKETS,
  },
  {
    name: "Bloom Bar",
    description: `Elevate your event with our on-site Bloom Bar from Back & Forth Farm! Guests can customize their own bouquets, selecting from our fresh blooms and expert guidance. Add a touch of floral flair and creativity to any occasion with this interactive and memorable experience.`,
    images: [BLOOM_BAR, STUDENT_GROUP_BAR, BLOOM_BAR_TWO],
    thumbnailImg: BLOOM_BAR_THUMBNAIL,
    type: EventType.BLOOM_BAR,
  },
  {
    name: "You Pick",
    description: `Experience the magic of harvesting your own flowers at Back & Forth Farm! Join us for our "Pick Your Own" events where you can wander through our picturesque fields, selecting the perfect blooms to create your custom bouquet. Enjoy a day of wholesome fun with your family and friends amidst the beauty of nature, making memories that bloom forever.`,
    images: [YOU_PICK_FARM, SHOES_AND_FLOWERS, WILLIAM_PUMPKIN],
    thumbnailImg: YOU_PICK_THUMBNAIL,
    type: EventType.YOU_PICK,
  },
  {
    name: "Weddings",
    description: `Create your dream wedding with the enchanting blooms from Back & Forth Farm! Elevate your special day with our exquisite flowers, handpicked and lovingly arranged to adorn your bouquet and decorate your venue. From romantic roses to whimsical wildflowers, our farm offers a diverse selection to complement any wedding theme or style. Let us be a part of your love story, adding the beauty of nature to every moment of your celebration.`,
    images: [JANINE_WEDDING, ZEUS_WEDDING],
    thumbnailImg: ZEUS_WEDDING_BRIDE,
    type: EventType.WEDDINGS,
  },
  {
    name: "Farm Photos",
    description:
      "Elevate your photography with the picturesque backdrop of Back & Forth Farm! Our charming farm offers the ideal setting for professional photo shoots, whether it's for engagements, family portraits, or commercial projects. Capture timeless moments amidst our lush fields, rustic barns, and blooming gardens, adding a touch of natural beauty and authenticity to your images. Book your session today and let the scenic vistas of our farm inspire your creativity.",
    images: ["/img/events/farmphotos.jpg"],
    thumbnailImg: "/img/events/farmphotos_thumbnail.jpg",
    type: EventType.FARM_PHOTOS,
  },
];
