import React from "react";
import { SUBSCRIPTIONS } from "../data/Subscriptions";
import { ImageCarouselWithTextSection } from "../shared/ImageCarouselWithTextSection";
import { PageFooter } from "../shared/PageFooter";
import { PageWithNavBar } from "../shared/PageWithNavBar";
import { SimpleTextBanner } from "../shared/SimpleTextBanner";
import { SimpleTextSection } from "../shared/SimpleTextSection";
import { useMobileMediaQuery } from "../state/ResponseivePage";
import styles from "../styles/Subscriptions.module.scss";
import { SubscriptionPreview } from "./SubscriptionPreview";

const SUBSCRIPTIONS_TITLE = "Fresh Flower Subscriptions";
const SUBSCRIPTIONS_DESCRIPTION =
  "We offer a spring and summer flower subscription, delivering fresh blooms straight from our local family-run farm to your door. With our service, you can enjoy regular deliveries of hand picked flowers, supporting sustainable farming practices and bringing beauty into your home or the homes of loved ones. Recipients will receive a handwritten personalized letter explaining the gift on the first week! Email backandforthfarms@gmail.com to reserve your subscription today (Limited supply).";

const BANNER_DESCRIPTOIN = (
  <React.Fragment>
    <p>
      <i>{`"Happiness held is the seed; happiness shared is the flower."`}</i>
    </p>
    <p>{`– John Harrigan`}</p>
  </React.Fragment>
);

export const Subscriptions = React.memo(function SubscriptionsInternal() {
  const isMobile = useMobileMediaQuery();

  return (
    <PageWithNavBar>
      <SimpleTextSection
        title={SUBSCRIPTIONS_TITLE}
        description={SUBSCRIPTIONS_DESCRIPTION}
      />
      <div className={styles.previewList}>
        {SUBSCRIPTIONS.map((subscription, index) => (
          <ImageCarouselWithTextSection
            key={subscription.type}
            images={subscription.images}
            title={subscription.name}
            description={
              <SubscriptionPreview
                // key={subscription.type}
                subscription={subscription}
              />
            }
            backgroundColor={subscription.color}
            portraitImages={true}
            imageRight={!isMobile && index % 2 === 1}
            endDivider={true}
          />
        ))}
      </div>
      <SimpleTextBanner description={BANNER_DESCRIPTOIN} />
      <PageFooter />
    </PageWithNavBar>
  );
});
