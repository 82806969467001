import classNames from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";
import { SOCIAL_MEDIA_SITES } from "../data/SocialMedia";
import styles from "../styles/NavigationBar.module.scss";

export const NavigationBar = React.memo(function BuzzFeedHeaderBarInternal() {
  return (
    <div className={classNames(styles.navigationBar, "bp5-elevation-2")}>
      <div className={styles.headerLogo}>
        <Link to={"/"}>
          <img
            className={styles.headerLogoImage}
            src="/img/logo/BackAndForthFarm_B&F_Slant_SmokeWhite.png"
            alt=""
          />
        </Link>
      </div>
      <div className={styles.navContainer}>
        <Link to={"/"} className={styles.navItem}>
          Home
        </Link>
        <Link to={"/about"} className={styles.navItem}>
          About Us
        </Link>
        <Link to={"/subscriptions"} className={styles.navItem}>
          Flower Subscriptions
        </Link>
        <Link to={"/events"} className={styles.navItem}>
          Events
        </Link>
        <Link to={"/wholesale"} className={styles.navItem}>
          Wholesale
        </Link>
      </div>
      {SOCIAL_MEDIA_SITES.map((site) => (
        <div className={styles.headerLogoSocial} key={site.name}>
          <img
            className={styles.headerLogoSocialImage}
            src={site.img}
            onClick={() => window.open(site.link, "_blank")}
            alt=""
          />
        </div>
      ))}
    </div>
  );
});
