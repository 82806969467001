import { ELEVATION_2 } from "@blueprintjs/core/lib/esm/common/classes";
import classNames from "classnames";
import React from "react";
import {
  BACK_AND_FORTH_SLANT_WITH_SPRIG_PRUSSIAN_BLUE,
  IMG_BULBS,
  IMG_FLOWER_BUCKET,
  IMG_LAND,
} from "../data/Images";
import { PageFooter } from "../shared/PageFooter";
import { PageWithNavBar } from "../shared/PageWithNavBar";
import { SimpleTextBanner } from "../shared/SimpleTextBanner";
import { useMobileMediaQuery } from "../state/ResponseivePage";
import styles from "../styles/Home.module.scss";

const LEFT_IMAGE_URL = IMG_FLOWER_BUCKET;
const MAIN_IMAGE_URL = IMG_LAND;
const RIGHT_IMAGE_URL = IMG_BULBS;
const MAIN_LOGO_URL = BACK_AND_FORTH_SLANT_WITH_SPRIG_PRUSSIAN_BLUE;

const TITLE = "Mission";
const DESCRIPTION =
  "At Back & Forth Farm, our mission is to provide beautiful blooms grown through sustainable practices and a touch of child labor ;-) in order to brighten spaces and occasions.";

export const Home = React.memo(function HomeInternal() {
  const isMobile = useMobileMediaQuery();

  return (
    <PageWithNavBar>
      <div
        className={classNames(styles.headerContainer, {
          [styles.mobile]: isMobile,
        })}
      >
        <div className={styles.imageCollage}>
          {!isMobile && (
            <div
              className={classNames(styles.supportImage, ELEVATION_2)}
              style={{
                backgroundImage: `url(${LEFT_IMAGE_URL})`,
              }}
            />
          )}
          <div className={classNames(styles.centerContainer, ELEVATION_2)}>
            <div className={styles.centerPadding} />
            <div
              className={styles.mainImage}
              style={{
                backgroundImage: `url(${MAIN_IMAGE_URL})`,
              }}
            />
          </div>
          {!isMobile && (
            <div
              className={classNames(styles.supportImage, ELEVATION_2)}
              style={{
                backgroundImage: `url(${RIGHT_IMAGE_URL})`,
              }}
            />
          )}
        </div>
        {isMobile && (
          <div className={styles.imageCollage}>
            <div
              className={classNames(styles.supportImage, ELEVATION_2)}
              style={{
                backgroundImage: `url(${LEFT_IMAGE_URL})`,
              }}
            />
            <div
              className={classNames(styles.supportImage, ELEVATION_2)}
              style={{
                backgroundImage: `url(${RIGHT_IMAGE_URL})`,
              }}
            />
          </div>
        )}
        <div className={styles.overlay}>
          <img className={styles.headerLogo} src={MAIN_LOGO_URL} alt="" />
        </div>
      </div>
      <SimpleTextBanner title={TITLE} description={DESCRIPTION} />
      <PageFooter />
    </PageWithNavBar>
  );
});
