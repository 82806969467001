import {
  ELEVATION_1,
  ELEVATION_2,
} from "@blueprintjs/core/lib/esm/common/classes";
import classNames from "classnames";
import React from "react";
import styles from "../styles/SimpleTextImageSection.module.scss";

interface SimpleTextImageSectionProps {
  title?: string;
  description: string | JSX.Element;
  image: string;
  imageRight?: boolean;
  endDivider: boolean;
  endDividerImage?: string;
}

export const SimpleTextImageSection = React.memo(
  function SimpleTextImageSectionInternal({
    title,
    description,
    image,
    imageRight,
    endDivider,
    endDividerImage,
  }: SimpleTextImageSectionProps) {
    const imageColumn = (
      <div className={styles.column}>
        <div className={classNames(styles.imageContainer, ELEVATION_2)}>
          <div
            className={styles.image}
            style={{
              backgroundImage: `url(${image})`,
            }}
          />
        </div>
      </div>
    );

    const textColumn = (
      <div
        className={classNames(styles.column, styles.textColumn, ELEVATION_1)}
      >
        {title !== "" ? <div className={styles.title}>{title}</div> : null}
        <div className={styles.description}>{description}</div>
        {endDivider && endDividerImage ? (
          <div>
            <img
              className={styles.decorationLogo}
              src={endDividerImage}
              alt=""
            />
          </div>
        ) : (
          <div className={styles.decorationDivider} />
        )}
      </div>
    );

    return (
      <div className={styles.baseContainer}>
        {imageRight ? (
          <React.Fragment>
            {textColumn} {imageColumn}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {imageColumn} {textColumn}
          </React.Fragment>
        )}
      </div>
    );
  }
);
