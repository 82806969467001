import { setWith, TypedReducer } from "redoodle";
import { EventType } from "../data/Events";
import { BaseAppActions } from "./BaseAppActions";
import { BaseAppState, getInitialBaseAppState, NavTab } from "./BaseAppState";

export const baseAppReducer = TypedReducer.builder<BaseAppState>()
  .withHandler(
    BaseAppActions.selectTab.TYPE,
    (state: BaseAppState, selectedTab: NavTab) => {
      return setWith(state, {
        selectedTab: selectedTab,
      });
    }
  )
  .withHandler(
    BaseAppActions.selectEvent.TYPE,
    (state: BaseAppState, selectedEvent: EventType) => {
      return setWith(state, {
        selectedEvent: selectedEvent,
      });
    }
  )
  .withDefaultHandler((_state) => getInitialBaseAppState())
  .build();
