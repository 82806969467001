import { Card } from "@blueprintjs/core";
import classNames from "classnames";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { Event } from "../data/Events";
import { BaseAppActions } from "../state/BaseAppActions";
import styles from "../styles/EventPreview.module.scss";

interface EventPreviewProps {
  event: Event;
  isSelected: boolean;
  onClick?: () => void;
}

export const EventPreview = React.memo(function EventPreview({
  event,
  isSelected,
  onClick,
}: EventPreviewProps) {
  const dispatch = useDispatch();

  const onEventClick = useCallback(() => {
    dispatch(BaseAppActions.selectEvent(event.type));
    if (onClick != null) {
      onClick();
    }
  }, [onClick, dispatch, event.type]);

  return (
    <Card
      interactive={true}
      className={classNames(styles.eventPreviewContainer, {
        [styles.selected]: isSelected,
      })}
      onClick={onEventClick}
    >
      <div
        className={styles.imageContainer}
        style={{
          backgroundImage: `url(${event.thumbnailImg})`,
        }}
      />
      <div className={styles.title}>{event.name}</div>
    </Card>
  );
});
