import { Button, Menu, MenuItem, Popover, Position } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import * as React from "react";
import { Link } from "react-router-dom";
import { SOCIAL_MEDIA_SITES } from "../data/SocialMedia";
import styles from "../styles/MobileNavigationBar.module.scss";

export const MobileNavigationBar = React.memo(
  function MobileNavigationBarInternal() {
    return (
      <div className={styles.mobileHeader}>
        <Popover
          className={styles.headerMenuPopover}
          content={
            <Menu className={styles.headerNavBarMenu}>
              <Link to={"/"} className={styles.navItem}>
                <MenuItem className={styles.mobileNavBarItem} text="Home" />
              </Link>
              <Link to={"/about"} className={styles.navItem}>
                <MenuItem className={styles.mobileNavBarItem} text="About Us" />
              </Link>
              <Link to={"/subscriptions"} className={styles.navItem}>
                <MenuItem
                  className={styles.mobileNavBarItem}
                  text="Flower Subscriptions"
                />
              </Link>
              <Link to={"/events"} className={styles.navItem}>
                <MenuItem className={styles.mobileNavBarItem} text="Events" />
              </Link>
              <Link to={"/wholesale"} className={styles.navItem}>
                <MenuItem
                  className={styles.mobileNavBarItem}
                  text="Wholesale"
                />
              </Link>
            </Menu>
          }
          position={Position.BOTTOM_RIGHT}
          minimal={true}
        >
          <Button
            icon={IconNames.MENU}
            minimal={true}
            className={styles.menuButton}
          />
        </Popover>
        <div className={styles.header}>
          <div className={styles.headerLogo}>
            <img
              className={styles.headerLogoImage}
              src="/img/logo/BackAndForthFarm_B&F_Slant_SmokeWhite.png"
              alt=""
            />
          </div>
        </div>
        <div className={styles.socialMediaSection}>
          {SOCIAL_MEDIA_SITES.map((site) => (
            <div className={styles.headerLogoSocial} key={site.name}>
              <img
                className={styles.headerLogoSocialImage}
                src={site.img}
                onClick={() => window.open(site.link, "_blank")}
                alt=""
              />
            </div>
          ))}
        </div>
      </div>
    );
  }
);
