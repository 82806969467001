import {
  IMG_BLACK_EYED_SUSAN,
  IMG_CELOSIA,
  IMG_CHINA_ASTER,
  IMG_COSMOS,
  IMG_DAFFODILS,
  IMG_DAHLIAS,
  IMG_GOMPHRENA,
  IMG_GRAPE_HYACINTH,
  IMG_HYACINTH,
  IMG_HYDRANGEA,
  IMG_SUNFLOWERS,
  IMG_TULIPS,
  IMG_ZINNIA,
} from "./Images";

export enum BloomSeason {
  SPRING = "SPRING",
  SUMMER = "SUMMER",
}

export enum CropType {
  STAPLE = "STAPLE",
  SPECIAL = "SPECIAL",
}

export interface Flower {
  name: string;
  image: string;
  season: BloomSeason;
  type: CropType;
  colors: Array<string>;
}

export const FLOWERS: Flower[] = [
  {
    name: "Gomphrena",
    image: IMG_GOMPHRENA,
    season: BloomSeason.SUMMER,
    type: CropType.STAPLE,
    colors: ["purple", "white", "purple/white mix", "red", "orange"],
  },
  {
    name: "Zinnia",
    image: IMG_ZINNIA,
    season: BloomSeason.SUMMER,
    type: CropType.STAPLE,
    colors: ["orange", "red", "coral", "pink", "green"],
  },
  {
    name: "Celosia",
    image: IMG_CELOSIA,
    season: BloomSeason.SUMMER,
    type: CropType.STAPLE,
    colors: [
      "yellow",
      "red",
      "dark pink",
      "light pink",
      "orange",
      "flamingo feather",
    ],
  },
  {
    name: "Sunflowers",
    image: IMG_SUNFLOWERS,
    season: BloomSeason.SUMMER,
    type: CropType.STAPLE,
    colors: [],
  },
  {
    name: "Cosmos",
    image: IMG_COSMOS,
    season: BloomSeason.SUMMER,
    type: CropType.STAPLE,
    colors: ["white", "blush", "deep pink/white"],
  },
  {
    name: "Hydrangea",
    image: IMG_HYDRANGEA,
    season: BloomSeason.SUMMER,
    type: CropType.STAPLE,
    colors: ["white", "white/green", "pink/green"],
  },
  {
    name: "Dahlias",
    image: IMG_DAHLIAS,
    season: BloomSeason.SUMMER,
    type: CropType.STAPLE,
    colors: ["white dinner plate", "purple", "red", "orange", "pink"],
  },
  {
    name: "China Aster",
    image: IMG_CHINA_ASTER,
    season: BloomSeason.SUMMER,
    type: CropType.STAPLE,
    colors: ["light pink", "purple"],
  },
  {
    name: "Black Eyed Susans",
    image: IMG_BLACK_EYED_SUSAN,
    season: BloomSeason.SUMMER,
    type: CropType.STAPLE,
    colors: [],
  },
  {
    name: "Tulips",
    image: IMG_TULIPS,
    season: BloomSeason.SPRING,
    type: CropType.STAPLE,
    colors: ["red", "red/white stripe", "orange/red", "yellow"],
  },
  {
    name: "Daffodils",
    image: IMG_DAFFODILS,
    season: BloomSeason.SPRING,
    type: CropType.STAPLE,
    colors: ["yellow/orange", "yellow/white", "white/coral"],
  },
  {
    name: "Grape Hyacinth",
    image: IMG_GRAPE_HYACINTH,
    season: BloomSeason.SPRING,
    type: CropType.STAPLE,
    colors: ["purple"],
  },
  {
    name: "Hyacinth",
    image: IMG_HYACINTH,
    season: BloomSeason.SPRING,
    type: CropType.STAPLE,
    colors: ["purple", "pink"],
  },
];
