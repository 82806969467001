import { ELEVATION_1 } from "@blueprintjs/core/lib/esm/common/classes";
import classNames from "classnames";
import React from "react";
import styles from "../styles/SimpleTextImageSection.module.scss";

interface SimpleTextSectionProps {
  title?: string;
  description: string | JSX.Element;
  backgroundColor?: string;
}

export const SimpleTextSection = React.memo(
  function SimpleTextImageSectionInternal({
    title,
    description,
    backgroundColor,
  }: SimpleTextSectionProps) {
    const textColumn = (
      <div
        className={classNames(styles.column, styles.textColumn, ELEVATION_1)}
        style={{ backgroundColor: backgroundColor }}
      >
        {title !== "" ? <div className={styles.title}>{title}</div> : null}
        <div className={styles.description}>{description}</div>
        <div className={styles.decoration}>
          <img
            className={styles.decorationLogo}
            src="/img/logo/BackAndForthFarm_SprigHorizontal_SmokeWhite.png"
            alt=""
          />
        </div>
      </div>
    );

    return <div className={styles.baseContainer}>{textColumn}</div>;
  }
);
