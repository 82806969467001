import React, { useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import { EVENTS } from "../data/Events";
import { SPRING_HORIZONTAL_SMOKE_WHITE } from "../data/Images";
import { ImageCarouselWithTextSection } from "../shared/ImageCarouselWithTextSection";
import { PageFooter } from "../shared/PageFooter";
import { PageWithNavBar } from "../shared/PageWithNavBar";
import { SimpleTextBanner } from "../shared/SimpleTextBanner";
import { SimpleTextSection } from "../shared/SimpleTextSection";
import { getSelectedEvent } from "../state/BaseAppSelectors";
import { useMobileMediaQuery } from "../state/ResponseivePage";
import styles from "../styles/Events.module.scss";
import { EventPreview } from "./EventPreview";

const EVENTS_TITLE = "Let us help host!";
const EVENTS_DESCRIPTION =
  "Make your next event truly unique with Back and Forth Farms' floral services. You'll be surprised by how therapeutic and creatively inspiring working with flowers can be, elevating your event to unforgettable heights. Click below to learn more!";

const BANNER_TITLE = "Don't see what you're looking for?";
const BANNER_DESCRIPTION =
  "We're passionate about sharing our farm with others, whether it's through engaging conversations, guided tours, or exploring new ideas to meet unexpected needs. Don't hesitate to get in touch with any thoughts or ideas you may have—we'd love to hear from you!";

export const Events = React.memo(function EventsInternal() {
  const isMobile = useMobileMediaQuery();
  const targetRef = useRef<HTMLDivElement>(null);

  const selectedEvent = useSelector(getSelectedEvent);
  const event = EVENTS.find((event) => event.type === selectedEvent);

  const scrollToDiv = useCallback(() => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({
        behavior: "smooth",
        block: isMobile ? "center" : "end",
      });
    }
  }, [isMobile]);

  return (
    <PageWithNavBar>
      <SimpleTextSection
        title={EVENTS_TITLE}
        description={EVENTS_DESCRIPTION}
      />
      <div className={styles.eventSelectionContainer}>
        {EVENTS.map((event) => (
          <EventPreview
            key={event.type}
            event={event}
            isSelected={selectedEvent === event.type}
            onClick={scrollToDiv}
          />
        ))}
      </div>
      <ImageCarouselWithTextSection
        images={event?.images || []}
        title={event?.name || ""}
        description={event?.description || ""}
        endDivider={true}
        endDividerImage={SPRING_HORIZONTAL_SMOKE_WHITE}
        backgroundColor="#592c28"
        carouselRef={targetRef}
        imageRight={isMobile}
      />
      <SimpleTextBanner title={BANNER_TITLE} description={BANNER_DESCRIPTION} />
      <PageFooter />
    </PageWithNavBar>
  );
});
